<template>
    <div class="row">
          <div class="col-md-12">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(register)">
                  <div class="row">
                    <div class="form-group col-md-4">
                      <ValidationProvider
                        name="Email"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <base-input
                          required
                          label="Email"
                          v-model="email"
                          placeholder="Email"
                          type="email"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-3">
                      <ValidationProvider
                        name="Buildings"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <label>Building</label>
                        <el-select
                          v-model="selects.building"
                          @change="getSubdivisions"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                         >     
                        <el-option
                          v-for="option in selects.buildings"
                          :key="option.value"
                          :label="option.label"
                          :value="option.value"
                        />
                   
                      </el-select>
                      
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-2">
                        <ValidationProvider
                          name="Permission"
                          rules="required"
                          v-slot="{ passed, failed, errors }"
                        >
                          <label>Permission</label>
                          <el-select
                            :disabled="!selects.building"
                            @change="getSubdivisions"
                            v-model="type_permission"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                          >
                            <el-option
                              value="full"
                              label="Full"
                            />
                            <el-option
                              value="partial"
                              label="Partial"
                            />
                          </el-select>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-3" v-if="type_permission ==='partial'" >
                        <ValidationProvider
                          name="Subdivision"
                          rules="required"
                          v-slot="{ passed, failed, errors }"
                        >
                          <label>Subdivision</label>
                          <el-select
                            multiple
                            v-model="selects.subdivision"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                            @change="limitsOptions"
                          >
                          
                            <el-option
                              v-for="option in selects.subdivisions"
                              :key="option.value"
                              :label="option.label"
                              :value="option.value"
                            />
                          </el-select>
                        </ValidationProvider>
                      </div>
                      
                  </div>
                  <button  native-type="submit" type="success" class="btn btn-info">Send</button>
                </form>
              </ValidationObserver>
  
            <div class="row mt-5">
                <div class="col-md-12">
                <card card-body-classes="table-full-width">
                    <div><h2 class="text-center">Permissions</h2>
                    <div
                        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                        <el-select
    
                        class="select-primary mb-3 pagination-select"
                        v-model="pagination.perPage"
                        placeholder="Per page"
                        >
                        <el-option
                            class="select-primary"
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                        </el-select>

                        <base-input>
                        <el-input
                            type="search"
                            class="mb-3 search-input"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="Search buildings"
                            v-model="searchQuery"
                            aria-controls="datatables"
                        >
                        </el-input>
                        </base-input>
                    </div>
                    <el-table :data="queriedData">
                        <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        :min-width="column.minWidth"
                        :prop="column.prop"
                        :label="column.label"
                        >
                        </el-table-column>
                        <el-table-column :min-width="90" align="right" label="Actions">
                        <div slot-scope="props">
                            <base-button
                            @click.native="handleEdit(props.$index, props.row)"
                            class="edit btn-link"
                            type="warning"
                            size="sm"
                            icon
                            >
                            <i class="tim-icons icon-pencil"></i>
                            </base-button>
                            <base-button
                            @click.native="handleDelete(props.$index, props.row)"
                            class="remove btn-link"
                            type="danger"
                            size="sm"
                            icon
                            >
                            <i class="fa fa-trash"></i>
                            </base-button> 
                        </div>
                        </el-table-column>
                    </el-table>
                    </div>
                    <div
                    slot="footer"
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                    <div class="">
                        <p class="card-category">
                        Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                        </p>
                    </div>
                    <base-pagination
                        class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total"
                    >
                    </base-pagination>
                    </div>
                </card>
                </div>
            </div>
            <modal 
            :show.sync="searchModalVisible"
            id="searchModal2 "
            :centered="false"
            :show-close="true"
            
            >
            <div>
            <div class="modal-header ">
                <h2 class="modal-title" style="color: white;">Edit Permissions</h2>
                <button type="button" class="close" @click="searchModalVisible = false">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form>
            <div >
                <div class="form-group">
                    <label for="name">Name:</label>
                    <input type="text" class="form-control" v-model="selectedRowCopy.name" id="name" readonly>
                </div>      
                <div class="form-group">
                    <label for="email">Email:</label>
                    <input type="email" class="form-control" v-model="selectedRowCopy.email" id="email" readonly>
                </div>
                <div class="form-group">
                  <label for="building">Building:</label>
                  <input type="text" class="form-control" v-model="selectedRowCopy.building" id="building" readonly>
              </div>
              <div class="form-group">
                <label for="permission">Permission:</label>
                <select class="form-control" v-model="selectedRowCopy.type_permission" >
                  <option value="full">Full</option>
                  <option value="partial">Partial</option>
                </select>
              </div>
            
            <div v-if="selectedRowCopy.type_permission === 'partial'">
              <div class="form-group">
                <label for="permission">Add subdivisions:</label>
                <el-select   v-model="selectedRowCopy.permissionsEdit" multiple  @change="limitsOptionsEdit">
                  <el-option v-for="(item) in selects.subdivisionsEdit.filter(subdivision => !selectedRowCopy.permissions.includes(subdivision.id))"
                  :key="item.id"
                  :label="item.complement_1"
                  :value="item.id"
                  >
                  {{ item.complement_1 }}
                </el-option>
                </el-select>
              </div>
            </div>
            <div v-if="selectedRowCopy.type_permission === 'partial'">
              <label class="pt-2">Subdivisions:</label>
              <div v-for="(permission, index) in selectedRowCopy.permissions" 
              :key="permission.id"
              :value="permission.id">
                <div class="row" style="padding-bottom: 2%;">
                  <div class="col-md-11">
                    <input  v-model="permission.name" type="text" class="form-control" readonly>
                  </div>
                  <button class="delete-button" @click="removePermission(index)"><i class="fa fa-trash"></i></button>
                </div>
              </div>
            </div>
          </div>
            </form>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"  @click="handleClose">Close</button>
                <button type="button" class="btn btn-primary" @click="handleSave()">Save changes</button>
            </div>
            </div>
            </modal>
            </div>
            </div>
  
     
  
  </template>
  
  <script>
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import supabase from "@/supabase.js";
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import { BasePagination ,Modal} from 'src/components';
  import Fuse from 'fuse.js';
  
  extend("required", required);
  
  export default {
    components: {
        Modal,
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.tableData.length;
      }
    },
  
    data() {
      return {
        deletedIds: [],
        email: "",
        useremail:'',
        userType:'',
        userId:'',
        PermissionUserAuth:'',
        selects: {
          building: [],
          buildings: [],
          subdivision: [],
          subdivisions: [],
          subdivisionsEdit: [],
        },
        type_permission:'',
        
      selectedBuilding: null,
      selectedRow: {},
          selectedRowCopy: {},
          selectedRowEdit: {},
          pagination: {
            perPage: 5,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0
          },
          id: 0,
          meterid: '',
          info: {},
          searchModalVisible: false,
          searchQuery: '',
          propsToSearch: ['name','email','building','type_permission','subdivisions'],
          tableColumns: [
            {
              prop: 'name',
              label: 'Name',
              minWidth: 200
            },
            {
              prop: 'email',
              label: 'Email',
              minWidth: 150
            },
            {
              prop: 'building',
              label: 'Building',
              minWidth: 100
            },
            {
              prop: 'type_permission',
              label: 'Type Permission',
              minWidth: 100
            },
            {
              prop: 'subdivisions',
              label: 'Subdivisions',
              minWidth: 150
            },

            
          ],
          tableData: [],
          searchedData: [],
          fuseSearch: null
      };
    },
  
    async created() {
      await this.getBuildings();
    },
    watch: {
        searchQuery(value) {
        this.searchedData = [];
        let result = this.tableData;
        if (value !== '') {
          const options = {
            caseSensitive: false,
            includeScore: false,
            shouldSort: true,
            shouldSortNumericStrings: true,
            threshold: 0.3,
            location: 0,
            distance: 100,
            useExtendedSearch: true,
            minMatchCharLength: 1,
            keys: ['name','email','building'],
          };
          const fuse = new Fuse(result, options);
          result = fuse.search(value).map(r => r.item);
          result.forEach(note => {
            this.searchedData.push(note);
          });
        }
      }

    },
    async mounted() {
        await this.loadData ();
    },
    methods: {
      limitsOptions() {
      if (this.selects.subdivision.length > this.selects.subdivisions.length -1) {
        this.selects.subdivision.pop();
      }
    },
    limitsOptionsEdit() {
      if (this.selectedRowCopy.permissionsEdit.length > (this.selects.subdivisionsEdit.filter(subdivision => !this.selectedRowCopy.permissions.includes(subdivision.id))).length -1) {
        this.selectedRowCopy.permissionsEdit.pop();
      }
    },
    removePermission(index) {
        const deletedPermission = this.selectedRowCopy.permissions.splice(index, 1)[0];
        this.deletedIds.push(deletedPermission.id);
        console.log(this.deletedIds)
        this.selects.subdivisionsEdit.push({
        id: deletedPermission.id,
        complement_1: deletedPermission.name
        });
        console.log(this.selects.subdivisionsEdit)

        },
    onBuildingChange() {
        if (this.selects.building.includes(null)) {
        this.selects.building = this.selects.buildings.map((option) => option.value);
        }
    },
    async IdBuildings (){
    const { data: { user }, error } = await supabase.auth.getUser();
        if (error) {
            console.error(error);
            return;
        }
        console.log(user)
        this.userType = user.user_metadata.userType; 
        this.userId = user.id;
        if((this.userType).includes("business-manager")){
          
          this.useremail = user.email;
          const { data: permissionData, error: permissionError } = await supabase
            .from("permissionbusinessmanagers")
            .select("id_building")
            .eq("id_businessmanager", this.userId);
    
              if (permissionError) {
              throw permissionError;
              }
    
          const { data: buildingData, error: buildingError } = await supabase
            .from("buildings")
            .select("*")
            .in("id", permissionData.map((permission) => permission.id_building));
    
              if (buildingError) {
              throw buildingError;
              }
              return buildingData;
        } else{
          this.useremail = user.email;
          const { data: permissionData, error: permissionError } = await supabase
            .from("permission")
            .select("id_building")
            .eq("id_manager", this.userId);
    
              if (permissionError) {
              throw permissionError;
              }
    
          const { data: buildingData, error: buildingError } = await supabase
            .from("buildings")
            .select("*")
            .in("id", permissionData.map((permission) => permission.id_building));
    
              if (buildingError) {
              throw buildingError;
              }
              return buildingData;
    
        }
        
  },
    async getBuildings() {
         const buildingData = await this.IdBuildings();
  
        this.selects.buildings = buildingData.map((building) => ({
          value: building.id,
          label: building.name,
        }));

      },

    async getSubdivisions() { 
      await this.IdBuildings();
      
      if(!(this.userType).includes('business-manager')){
        const { data, error } = await supabase
            .from('permission')
            .select('type_permission')
            .eq("id_manager", this.userId)
           
        if(error){
            alert(error.message);
            return;
        }
        this.PermissionUserAuth = data[0].type_permission; 
      }
      
      if(this.PermissionUserAuth === 'full' || (this.userType).includes('business-manager')){
        const { data, error } = await supabase
            .from('buildingsubdivision')
            .select("*")
            .eq( "id_building", this.selects.building)
        if(error){
            alert(error.message);
            return;
        }
        console.log(data)
        this.selects.subdivisions = data.map((subdivision) => ({
          value: subdivision.id,
          label: subdivision.complement_1,
        }));
      } else {
        const { data, error } = await supabase
          .from('permission')
          .select(`
            buildingsubdivision(
                id,
                complement_1
            )
            `)
          .eq("id_manager", this.userId)
        if(error){
          alert(error.message);
          return;
        }
        console.log(data)
        this.selects.subdivisions = data.map((subdivision) => ({
          value: subdivision.buildingsubdivision.id,
          label: subdivision.buildingsubdivision.complement_1,
        }));
        
      }

      },
      async register(){
        
        const { data, error } = await supabase
            .from('managers')
            .select('id')
            .eq('email', this.email)
        if(data.length === 0){
            alert('Email não encontrado')
            return
        }
         else if(error){
            alert(error.message);
            return;
        }
        const id_manager = data[0].id;
        console.log(this.selects.subdivision);
        console.log(this.type_permission)
        if(this.type_permission === 'full'){
            const { error} = await supabase
                .from('permission')
                .insert({ id_manager:id_manager,id_building: this.selects.building,type_permission:this.type_permission});

            if (error) {
                if(error.code === '23505'){
                    //
                } else{
                    alert(error.message);
                    return;
                }  
            }
        }
        else{
       
            for (const subdivision of this.selects.subdivision) {
                const { error } = await supabase
                    .from('permission')
                    .insert({ id_manager:id_manager , id_buildingsubdivision:subdivision,id_building: this.selects.building,type_permission:this.type_permission});

                if (error) {
                    if(error.code === '23505'){
                        continue;
                    } else{
                        alert(error.message);
                        return;
                    }  
                }
            } 
        }
        alert('Permissão adicionada com sucesso!')
        this.email='';
        this.selects.building = '';
        this.selects.subdivision = '';
        this.loadData();
      },

      async loadData () {
        const ids = await this.IdBuildings();
        console.log('id',ids)
        const { data, error } = await supabase
            .from('permission')
            .select(`
            id,
            type_permission,
            managers (
                id,
                name,
                email
            ),
            buildings(
                id,
                name
            ),
            buildingsubdivision(
                id,
                complement_1
            )
            `)
            .in('id_building', ids.map((building) => building.id));
                
        if (error) {
            console.error(error)
        } else {
            console.log(data) 
        }

        const result = {};

        data.forEach(obj => {
        if (!result[obj.managers.id]) {
            result[obj.managers.id] = {
            name: obj.managers.name,
            email: obj.managers.email,
            id_manager: obj.managers.id,
            type_permission: obj.type_permission,
            buildings: obj.buildings.name,
            permissions: [],
            subdivisions:{},
            building_id:obj.buildings.id
            };
        }
        if (obj.buildingsubdivision !== null) {
        
        const permission = { 
            name: obj.buildingsubdivision.complement_1,
            id: obj.buildingsubdivision.id
        };
        result[obj.managers.id].permissions.push(permission); }
        
        });
        console.log(result)
        console.log(this.useremail)
        var output = Object.values(result);
        for (let i = 0; i < output.length; i++) {
        if (output[i].email === this.useremail) {
            output.splice(i, 1);
            break; 
        }
        }
        console.log(output);
       
       
        this.tableData = output.map(permission => {
          const subdivisionsList = permission.permissions.map(subdivision => subdivision.name).join(", ");
          return {
            id_manager: permission.id_manager,
            name: permission.name,
            email: permission.email,
            id_permission: permission.id_permission,
            building: permission.buildings,
            type_permission: permission.type_permission,
            permissions: permission.permissions,
            building_id: permission.building_id,
            subdivisions: subdivisionsList
          };
      });
        this.fuseSearch = new Fuse(this.tableData, {
            keys: ['name', 'email', 'building','id_manager','id_permission','permissions','subdivisions','building_id'],
            threshold: 0.3
        });
    },
        handleEdit(index, row) {
        this.selectedRow = row;
        this.selectedRowCopy = { ...row };
        this.searchModalVisible = true;
        //this.selectedRowCopy.permissionsEdit = this.selectedRowCopy.permissions.map((permission) => permission.id);
        this.addPartialPermission(this.selectedRowCopy.building_id);
        },

        async addPartialPermission(building_id){
          console.log(building_id);
          const { data, error } = await supabase
            .from('buildingsubdivision')
            .select("complement_1,id")
            .eq('id_building',building_id)
          if(error){
            alert(error.message);
            return;
          }
          this.selects.subdivisionsEdit = data.filter(option => { return !this.selectedRowCopy.permissions.some(permission => permission.id === option.id)})
          console.log( this.selects.subdivisionsEdit)
        },

        handleClose() {
            this.loadData ();
            this.deletedIds = [];
            this.searchModalVisible = false;
        },

        async handleDelete(index, row){
          console.log(row)
          const { error } = await supabase
            .from('permission')
            .delete()
            .eq('id_manager', row.id_manager)
          if(error){
            alert(error.message);
            return;
          }
          alert('Permissão excluida com sucesso!')
            this.loadData ();
                
        },
        async handleSave() {
          this.searchModalVisible = false;
          console.log(this.selectedRowCopy)
          this.selectedRowEdit = this.selectedRowCopy;
          const permission = [
          ...this.selectedRowCopy.permissions.map((permission) => permission.id),
          ...this.selectedRowCopy.permissionsEdit
          ];

          const { error } = await supabase
            .from('permission')
            .delete()
            .eq('id_manager', this.selectedRowCopy.id_manager)
          if(error){
            alert(error.message);
            return;
          }
          console.log('edit',this.selectedRowEdit.type_permission)
          if(this.selectedRowEdit.type_permission === 'full'){
            const { error } = await supabase
            .from('permission')
            .insert({ 
              id_building: this.selectedRowEdit.building_id,
              type_permission: this.selectedRowEdit.type_permission,
              id_manager: this.selectedRowEdit.id_manager
            });
            if (error) {
              console.error(error.message);
              return;
            }
            this.loadData ();
            alert('Permissão Atualizada com sucesso!');
          } else {
          for (let i = 0; i < permission.length; i++) {
            const permissionId = permission[i];
            console.log(permissionId);
            console.log(permission[i]);
            const { error } = await supabase.from('permission').insert([{ 
              id_buildingsubdivision: permissionId,
              id_building: this.selectedRowEdit.building_id,
              type_permission: this.selectedRowEdit.type_permission,
              id_manager: this.selectedRowEdit.id_manager
            }]);
            if (error) {
              console.error(error.message);
              return;
            }
            
          }
          this.loadData ();
            alert('Permissão Atualizada com sucesso!');
          }

        },
        
  
    },
  };
  </script>
  <style>
  .pagination-select,
  .search-input {
    width: 200px;
  }
  .swal2-icon-content{
    font-size: inherit !important;
  }
  .modal-body  {
    background-color: #27293D; 
    
  }
  .modal-title{
    padding-bottom: 8%;
  }
  .el-table th.el-table__cell {
    
    background-color: rgba(255, 255, 255, 0);
}
.delete-button {
    color: red;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .el-input.is-disabled .el-input__inner {
    background-color:#1E1E2E;
    border-color: #33416e;
    color: #3c434b;
    cursor: not-allowed;
    border-width: 0.1px;
}
.form-control option {
  color: black;
}

  </style>
  