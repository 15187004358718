<template>
    <div class="row">
          <div class="col-md-12">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(register)">
                  <div class="row">
                    <div class="form-group col-md-5">
                      <ValidationProvider
                        name="Email"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <base-input
                          required
                          label="Email"
                          v-model="email"
                          placeholder="Email"
                          type="email"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-4">
                      <ValidationProvider
                        name="Buildings"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <label>Building</label>
                        <el-select
                          multiple
                          v-model="selects.building"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                          @change="onBuildingChange"
                         >     
                         <el-option
                          :key="'select-all'"
                          :label="'Select All'"
                          :value="null"  
                          
                        />
                        <el-option
                          v-for="option in selects.buildings"
                          :key="option.value"
                          :label="option.label"
                          :value="option.value"
                        
                          
                        />
                   
                      </el-select>
                      
                      </ValidationProvider>
                    </div>
                  </div>
                  <button  native-type="submit" type="success" class="btn btn-info">Send</button>
                </form>
              </ValidationObserver>
  
            <div class="row mt-5">
                <div class="col-md-12">
                <card card-body-classes="table-full-width">
                    <div><h2 class="text-center">Permissions</h2>
                    <div
                        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                        <el-select
                        class="select-primary mb-3 pagination-select"
                        v-model="pagination.perPage"
                        placeholder="Per page"
                        >
                        <el-option
                            class="select-primary"
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                        </el-select>

                        <base-input>
                        <el-input
                            type="search"
                            class="mb-3 search-input"
                            clearable
                            prefix-icon="el-icon-search"
                            placeholder="Search buildings"
                            v-model="searchQuery"
                            aria-controls="datatables"
                        >
                        </el-input>
                        </base-input>
                    </div>
                    <el-table :data="queriedData">
                        <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        :min-width="column.minWidth"
                        :prop="column.prop"
                        :label="column.label"
                        >
                        </el-table-column>
                        <el-table-column :min-width="90" align="right" label="Actions">
                        <div slot-scope="props">
                            <base-button
                            @click.native="handleEdit(props.$index, props.row)"
                            class="edit btn-link"
                            type="warning"
                            size="sm"
                            icon
                            >
                            <i class="tim-icons icon-pencil"></i>
                            </base-button>
                            <base-button
                            @click.native="handleDelete(props.$index, props.row)"
                            class="remove btn-link"
                            type="danger"
                            size="sm"
                            icon
                            >
                            <i class="fa fa-trash"></i>
                            </base-button> 
                        </div>
                        </el-table-column>
                    </el-table>
                    </div>
                    <div
                    slot="footer"
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                    <div class="">
                        <p class="card-category">
                        Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                        </p>
                    </div>
                    <base-pagination
                        class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total"
                    >
                    </base-pagination>
                    </div>
                </card>
                </div>
            </div>
            <modal 
            :show.sync="searchModalVisible"
            
            id="searchModal2 "
            :centered="false"
            :show-close="true"
            
            >
            <div>
            <div class="modal-header">
                <h2 class="modal-title" style="color: white;">Edit Permissions</h2>
                <button type="button" class="close" @click="searchModalVisible = false">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form>
            <div >
                <div class="form-group">
                    <label for="name">Name:</label>
                    <input type="text" class="form-control" v-model="selectedRowCopy.name" id="name" readonly>
                </div>      
                <div class="form-group">
                    <label for="email">email:</label>
                    <input type="email" class="form-control" v-model="selectedRowCopy.email" id="email" readonly>
                </div>
            </div>
            <div>
                <label>Permissions:</label>
                <div v-for="(building, index) in selectedRowCopy.permissions" :key="building.id">
                    <div class="row" style="padding-bottom: 2%;">
                        <div class="col-md-11">
                            <input :id="'building-name-' + index" v-model="building.name" type="text" class="form-control" readonly>
                        </div>
                  <button class="delete-button" @click="removeBuilding(index)"><i class="fa fa-trash"></i></button>
                    </div>
                </div>
              </div>
            </form>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"  @click="handleClose">Close</button>
                <button type="button" class="btn btn-primary" @click="handleSave()">Save changes</button>
            </div>
            </div>
            </modal>
            </div>
            </div>
  
     
  
  </template>
  
  <script>
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import supabase from "@/supabase.js";
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import { BasePagination ,Modal} from 'src/components';
  import Fuse from 'fuse.js';
  
  extend("required", required);
  
  export default {
    components: {
        Modal,
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.tableData.length;
      }
    },
  
    data() {
      return {
        deletedIds: [],
        email: "",
        useremail:'',
        selects: {
          building: [],
          buildings: [],
        },
        subdivisions: [
        { name: "Subdivision 1", value: "" },
      ],
      selectedBuilding: null,
      selectedRow: {},
          selectedRowCopy: {},
          pagination: {
            perPage: 5,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0
          },
          id: 0,
          meterid: '',
          info: {},
          searchModalVisible: false,
          searchQuery: '',
          propsToSearch: ['name','email','building'],
          tableColumns: [
            {
              prop: 'name',
              label: 'Name',
              minWidth: 200
            },
            {
              prop: 'email',
              label: 'Email',
              minWidth: 150
            },
            {
              prop: 'buildings',
              label: 'Buldings',
              minWidth: 150
            },
            
          ],
          tableData: [],
          searchedData: [],
          fuseSearch: null
      };
    },
  
    async created() {
      await this.getBuildings();
    },
    watch: {
        searchQuery(value) {
        this.searchedData = [];
        let result = this.tableData;
        if (value !== '') {
          const options = {
            caseSensitive: false,
            includeScore: false,
            shouldSort: true,
            shouldSortNumericStrings: true,
            threshold: 0.3,
            location: 0,
            distance: 100,
            useExtendedSearch: true,
            minMatchCharLength: 1,
            keys: ['name','email','building'],
          };
          const fuse = new Fuse(result, options);
          result = fuse.search(value).map(r => r.item);
          result.forEach(note => {
            this.searchedData.push(note);
          });
        }
      }

    },
    async mounted() {
        await this.loadData ();
    },
    methods: {
        removeBuilding(index) {
      const deletedBuilding = this.selectedRowCopy.permissions.splice(index, 1)[0];
      this.deletedIds.push(deletedBuilding.id);
      console.log(this.deletedIds)
      console.log(this.selectedRow)
    },
        onBuildingChange() {
    if (this.selects.building.includes(null)) {
      // "Select All" option is selected, set v-model to all values
      this.selects.building = this.selects.buildings.map((option) => option.value);
    }
  },
  async IdBuildings (){
    const { data: { user }, error } = await supabase.auth.getUser();
        if (error) {
            console.error(error);
            return;
        }
        const userId = user.id;
        //console.log('User ID:', userId);
        //console.log(user)
        this.useremail = user.email;
        const { data: permissionData, error: permissionError } = await supabase
          .from("permissionbusinessmanagers")
          .select("id_building")
          .eq("id_businessmanager", userId);
  
            if (permissionError) {
            throw permissionError;
            }
  
        const { data: buildingData, error: buildingError } = await supabase
          .from("buildings")
          .select("*")
          .in("id", permissionData.map((permission) => permission.id_building));
  
            if (buildingError) {
            throw buildingError;
            }
            return buildingData;
    
  },
    async getBuildings() {
         const buildingData = await this.IdBuildings();
  
        this.selects.buildings = buildingData.map((building) => ({
          value: building.id,
          label: building.name,
        }));

      },

      async register(){
        
        const { data, error } = await supabase
            .from('businessmanagers')
            .select('id')
            .eq('email', this.email)
        if(data.length === 0){
            alert('Email não encontrado')
            return
        }
         else if(error){
            alert(error.message);
            return;
        }
        const id_businessmanager = data[0].id;
        console.log(this.selects.building);
        for (const buildings of this.selects.building) {
            const { error } = await supabase
                .from('permissionbusinessmanagers')
                .insert({ id_businessmanager:id_businessmanager , id_building:buildings});

            if (error) {
                if(error.code === '23505'){
                    continue;
                } else{
                    alert(error.message);
                    return;
                }  
            }
        }
        alert('Permissão adicionada com sucesso!')
        this.email='';
        this.selects.building = '';
        this.loadData();
      },

      async loadData () {
        const ids = await this.IdBuildings();
        const { data, error } = await supabase
            .from('permissionbusinessmanagers')
            .select(`
            id,
            businessmanagers (
                id,
                name,
                email
            ),
            buildings(
                id,
                name
            )
            `)
            .in('id_building', ids.map((building) => building.id));
                
        if (error) {
            console.error(error)
        } else {
            console.log(data) 
        }

        const result = {};

        data.forEach(obj => {
        if (!result[obj.businessmanagers.id]) {
            result[obj.businessmanagers.id] = {
            name: obj.businessmanagers.name,
            email: obj.businessmanagers.email,
            id_businessmanager: obj.businessmanagers.id,
            buildings: {},
            permissions: [] 
            };
        }
        const building = {
            id: obj.buildings.id,
            name: obj.buildings.name
        };
        result[obj.businessmanagers.id].buildings[building.id] = building.name;
        const permission = { 
            name: obj.buildings.name,
            id: obj.id
        };

        result[obj.businessmanagers.id].permissions.push(permission); 
        });

        console.log(this.useremail)
        var output = Object.values(result);
        for (let i = 0; i < output.length; i++) {
        if (output[i].email === this.useremail) {
            output.splice(i, 1);
            break; 
        }
        }
        console.log(output);
         
        this.tableData = output.flatMap(building => {
            const buildingList = Object.values(building.buildings).join(', ');
            console.log(buildingList)
            return {
            id_businessmanager: building.id_businessmanager,
            name: building.name,
            email: building.email,
            id_permission: building.id_permission,
            buildings: buildingList,
            permissions:building.permissions
            };
        });

        this.fuseSearch = new Fuse(this.tableData, {
            keys: ['name', 'email', 'buildings','id_businessmanager','id_permission','permissions'],
            threshold: 0.3
        });
    },



        handleEdit(index, row) {
        this.selectedRow = row;
        this.selectedRowCopy = { ...row };
        this.searchModalVisible = true;
        console.log(row.permissions)
        },

        handleClose() {
            this.loadData ();
            this.deletedIds = [];
            this.searchModalVisible = false;
        },

        async handleDelete(index, row){
            console.log(row)
            const id_businessmanager =row.id_businessmanager
            console.log('id',id_businessmanager)

            const { error } = await supabase
                .from('permissionbusinessmanagers')
                .delete()
                .in('id', (row.permissions).map((permissionid) => permissionid.id))
            if(error){
                alert(error.message);
                return;
            }
            /*const { error2 } = await supabase
                .from('businessmanagers')
                .delete()
                .eq('id', id_businessmanager)
            if(error2){
                alert(error.message);
                return;
            }*/
            this.loadData ();
                
        },
        async handleSave() {
        try {
                
        const { error } = await supabase
        .from('permissionbusinessmanagers')
        .delete()
        .in('id', this.deletedIds)

        if (error){
            console.log(error.message);
            return;
        }

        this.searchModalVisible = false;
        console.log('Permissions updated successfully');

            
            if (this.deletedIds !== null) {
                this.loadData ();
            }
            this.selectedRow = {};
            this.deletedIds = [];

        } catch (error) {
        console.error(error); 
        console.log('Error updating subdivision');
        }
        },
        
  
    },
  };
  </script>
  <style>
  .pagination-select,
  .search-input {
    width: 200px;
  }
  .swal2-icon-content{
    font-size: inherit !important;
  }
  .modal-body  {
    background-color: #27293D; 
    
  }
  .modal-title{
    padding-bottom: 8%;
  }
  .el-table th.el-table__cell {
    
    background-color: rgba(255, 255, 255, 0);
}
.delete-button {
    color: red;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  </style>
  